<template>
<div>
    <h1>BlogList</h1>

    <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="index" width="100">
        </el-table-column>
        <el-table-column prop="_id" label="id" width="120">
        </el-table-column>
        <el-table-column prop="title" label="标题" width="120">
        </el-table-column>
        <el-table-column prop="abstract" label="简介" width="120">
        </el-table-column>
        <el-table-column prop="target" label="标签" width="120">
        </el-table-column>
        <el-table-column style="display:none" prop="content" label="内容" width="120">略
        </el-table-column>
        <el-table-column label="操作" width="100">
            <template v-on="touchmove" slot-scope="scope">
                <el-button @click="modifyBlog(scope.row)" type="text" size="small">编辑</el-button>
                <el-button type="text" size="small" @click="deleteData(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
</div>
</template>

<script scoped>
import url from "@/serviceAPI.config.js";
import axios from "axios";
export default {
    data() {
        return {
            tableData: [],
        }
    },
    methods: {
        handleClick(row) {
            console.log(row);
        },
        getdata() {

            axios({
                    url: url.blog,
                    method: "get",
                })
                .then((response) => {
                    this.tableData = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.getdata()
                    // ！！！出现错误怎么优化，暂时用刷新
                    // location.reload();
                });
        },
        async deleteData(row) {
            this.$confirm(`确定移除 ${row.title}？`, {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
            }).then(async (res) => {
                console.log(row._id)
                if (res == "confirm") {
                    axios({
                            url: url.blog,
                            method: "delete",
                            data: {
                                data: row,
                            },
                        })
                        .then((response) => {
                            // this.tableData = response.data.data;
                            console.log(response)
                        })
                        .catch((error) => {
                            console.log(error);
                            // ！！！出现错误怎么优化，暂时用刷新
                            // location.reload();
                            this.getdata()
                        });
                }
            })
            this.getdata()
        },
        async modifyBlog(row) {
            this.$store.commit('blogState', true)
            this.$router.push({
                path: '/admin/write',
                query: {
                    id: row
                }
            })
        }
    },
    created() {
        this.getdata()
    }
}
</script>

<style scoped>

</style>
